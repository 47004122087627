import { Link } from "gatsby";
import React, { useEffect, useRef, useState } from "react"
import {useSelector} from 'react-redux';

import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductGrid from "../components/elements/ProductGrid"
import Sort from "../components/elements/Sort";
import { getFavourites } from "../functions/favourite";

const axios = require('axios');

const FavouritesPage = props => {

    const [products, setProducts] = useState([]);
    const [sortedBy, setSortedBy] = useState('');
    const [showMobileSort, setShowMobileSort] = useState(false);
    const favouritesStore = useSelector(state => state?.favourites);

    // GET PRODUCTS

    useEffect(() => {

        const thisProducts = [...products];

        if (thisProducts.length > 0) {

            switch (sortedBy) {
                case 'new':
                    thisProducts.sort((a, b) => {

                        return parseInt(b.id) - parseInt(a.id);

                    });

                    break;

                case 'priceup':
                    thisProducts.sort((a, b) => {

                        let priceA = parseFloat(a.price.replace(',', '.'));
                        let priceB = parseFloat(b.price.replace(',', '.'));

                        return priceA - priceB;

                    });

                    break;

                case 'pricedowm':
                    thisProducts.sort((a, b) => {

                        let priceA = parseFloat(a.price.replace(',', '.'));
                        let priceB = parseFloat(b.price.replace(',', '.'));

                        return priceB - priceA;

                    });

                    break;

            }

            setProducts(thisProducts);

        }


    }, [sortedBy]);


    return (
        <Layout>
            <SEO title={'Angebote'} />
            <div className={`container`} style={{ minHeight: '90vh', paddingTop: 100 }}>
                <div className={`col12`}>
                    <div>
                        <h1>Deine Favoriten</h1>

                        <div className={`sortWrapper ${showMobileSort ? "active" : null}`}>
                            <Sort
                                title="Sortieren nach"
                                products={products}
                                value={sortedBy}
                                options={[
                                    {
                                        'title': 'Neuste zuerst',
                                        'id': 'new'
                                    },
                                    {
                                        'title': 'Preis aufsteigend',
                                        'id': 'priceup',
                                    },
                                    {
                                        'title': 'Preis Absteigend',
                                        'id': 'pricedowm'
                                    }
                                ]}
                                onChange={(value) => setSortedBy(value)}
                            />

                        </div>
                        <ProductGrid
                            products={favouritesStore}
                            perLine={4}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default FavouritesPage